<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/username2.png" class="icon" />
            <input type="text" v-model="username" class="input" placeholder="请输入用户名" />
          </div>
          <div class="item">
            <img src="@/assets/pass.png" class="icon" />
            <input type="text" v-model="password" class="input" placeholder="请输入密码" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  timer: '',
  data() {
    return {
      password: '',
      hrefurl: '',
      username: '',
      state:false,
      time: 0
    };
  },
  created() {
    this.hrefurl = location.href;
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {

    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.username)) {
        Toast('用户名不可以为空!');
        this.state = false;
        return;
      }
      if (isEmpty(this.password)) {
        Toast('密码不可以为空!');
        this.state = false;
        return;
      }

      let { data } = await this.axios.post(
        '/ctid/tlService/login_user',
        qs.stringify({ 
          username: this.username, 
          password: this.password 
        })
      );
      if (data.flag === '0') {
        this.state = false;
        this.$router.replace({path:'/car/car_list', query:{
          hrefurl:this.hrefurl
        }});
      } else {
        this.state = false;
        Toast(data.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.checkcode {
  position: absolute;
  line-height: 44px;
  height: 44px;
  right: 0;
  top: 35px;
  color: rgba(0, 49, 255, 1);
}
</style>